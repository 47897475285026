
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IMatching } from '@/types/offer.types';

// Config Variables
import { ActiveTenant } from '../../../../active-tenant';
import { tenants } from '../../../../tenant-config';

@Component
export default class boostChip extends Vue {
  @Prop({ required: true }) chip!: IMatching;

  accentText = tenants[ActiveTenant].text.boosterCardAccent;

  getBrandName(): string {
    return this.chip.brand?.name ?? '';
  }

  get formattedMatchingValue(): string {
    const { matchingValueType, matchingValue } = this.chip;

    if (matchingValueType === 'fixed_amount') {
      return `$${Number.isInteger(matchingValue) ? matchingValue : matchingValue.toFixed(2)}`;
    } else {
      return `${Number.isInteger(matchingValue) ? matchingValue : matchingValue.toFixed(2)}%`;
    }
  }
}
